<script lang="ts" setup> 

    const props = defineProps({ 
        blok: Object 
    })

    const richtext = computed(() => renderRichText(props.blok.richtext))  

</script>

<template>
   <div v-editable="blok" class="grid grid-col-1 lg:grid-cols-2 gap-6 lg:gap-8" :class="[blok?.theme || 'dark']">
        <div class="flex flex-col gap-4" :class="{'order-1 lg:order-2': blok?.reverse_columns}">
            <div v-if="blok?.heading || blok?.subheading" class="flex flex-col gap-2">
                <h3 v-if="blok?.heading" v-html="blok.heading" class="font-hass-display text-h2_mobile md:text-h2_tablet lg:text-h2_desktop font-medium text-white-50 dark:text-elephant-950 [&>em]:not-italic [&>em]:text-lima-600"/>
                <h4 v-if="blok?.subheading" v-html="blok.subheading" class="font-hass-display text-h6_mobile md:text-h6_tablet lg:text-h6_desktop font-medium text-white-50 dark:text-elephant-950"/>
            </div>
            <div v-if="blok?.richtext" v-html="richtext" class="flex flex-col gap-2 | font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-normal text-white-50/75 dark:text-nevada-500 [&>*>a]:text-lima-600 [&>*>a]:underline | [&>*>ul]:flex [&>*>ul]:flex-col [&>*>ul]:gap-2  | [&>*>li]:relative [&>*>li]:before:content-[''] [&>*>li]:before:absolute [&>*>li]:before:top-1/2 [&>*>li]:before:left-0 [&>*>li]:before:w-1 [&>*>li]:before:-translate-y-1/2 [&>*>li]:before:h-1 [&>*>li]:before:rounded-full [&>*>li]:before:bg-lima-600 [&>*>li]:pl-3"/>
            <div v-if="blok?.buttons" class="flex flex-wrap gap-2">
                <StoryblokComponent v-for="button in blok.buttons" :key="button._uid" :blok="button" />
            </div>
        </div>
        <div v-if="blok?.images" class="flex flex-col gap-4" :class="{'order-2 lg:order-1': blok?.reverse_columns}">
            <StoryblokComponent v-for="image in blok.images" :key="image._uid" :blok="image" class="rounded-1"/>
        </div>
   </div>
</template>